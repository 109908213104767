import { USD } from '@dinero.js/currencies';

const ACTION_ID_CATEGORIES_FETCH = 'categories-fetch';
const ACTION_ID_CATEGORIES_FETCH_SETTINGS = 'categories-fetch-settings';
const ACTION_ID_CATEGORIES_SAVE = 'categories-save';
const ACTION_ID_COEFFS_FETCH = 'coeffs-fetch';
const ACTION_ID_COEFFS_SAVE = 'coeffs-save';
const ACTION_ID_USER_SETTINGS_FETCH = 'user-settings-fetch';
const ACTION_ID_ASSOCIATIONS_FETCH_EXCEL = 'associations-fetch-excel';
const ACTION_ID_ASSOCIATIONS_UPLOAD_EXCEL = 'associations-upload-excel';
const ACTION_ID_STORAGES_FETCH = 'storages-fetch';
const ACTION_ID_STORAGES_SAVE = 'storages-save';
const ACTION_ID_PRICES_MONITORING_FETCH = 'prices-monitoring-fetch';
const ACTION_ID_PRICES_MONITORING_FETCH_EXCEL = 'prices-monitoring-fetch-excel';
const ACTION_ID_PRICES_MONITORING_FETCH_PRICES = 'prices-monitoring-fetch-prices';
const ACTION_ID_PAGES_FETCH = 'pages-fetch';
const ACTION_ID_CONFIGURATIONS_FETCH = 'configurations-fetch';
const ACTION_ID_CONFIGURATIONS_FETCH_EXCEL = 'configurations-fetch-excel';
const ACTION_ID_CONFIGURATIONS_FETCH_DETAIL = 'configurations-fetch-detail';
const ACTION_ID_CONFIGURATIONS_FETCH_SUM = 'configurations-fetch-sum';
const ACTION_ID_CONFIGURATIONS_SEND_TO_CRM = 'configurations-send-to-crm';
const ACTION_ID_CONFIGURATIONS_SAVE = 'configurations-save';
const ACTION_ID_CONFIGURATIONS_DELETE = 'configurations-delete';
const ACTION_ID_CONFIGURATIONS_COPY = 'configurations-copy';
const ACTION_ID_CONFIGURATIONS_UPLOAD = 'configurations-upload';
const ACTION_ID_CONFIGURATIONS_UPLOAD_CANCEL = 'configurations-upload-cancel';
const ACTION_ID_CONFIGURATIONS_SPECIFY = 'configurations-specify';
const ACTION_ID_CONFIGURATIONS_FETCH_HISTORY = 'configurations-fetch-history';
const ACTION_ID_CONFIGURATOR_INIT = 'configurator-init';
const ACTION_ID_CONFIGURATOR_VALIDATE = 'configurator-validate';
const ACTION_ID_CONFIGURATOR_FETCH_FILTERS = 'configurator-fetch-filters';
const ACTION_ID_CONFIGURATOR_FETCH_FIELDS = 'configurator-fetch-fields';
const ACTION_ID_CONFIGURATOR_FETCH_RANDOM_ARTICUL = 'configurator-fetch-random-articul';
const ACTION_ID_CONFIGURATOR_FETCH_SLOT_SEARCH_PRODUCTS = 'configurator-fetch-slot-search-products';
const ACTION_ID_CONFIGURATOR_FETCH_SLOT_SEARCH_PRODUCTS_ATTRIBUTES = 'configurator-fetch-slot-search-products-attributes';
const ACTION_ID_CONFIGURATOR_SET_SLOT_SEARCH_PRODUCTS_RECOMMENDATION = 'configurator-set-slot-search-products-recommendation';
const ACTION_ID_PRODUCTS_FETCH = 'products-fetch';
const ACTION_ID_PRODUCTS_FETCH_FORM = 'products-fetch-form';
const ACTION_ID_PRODUCTS_FETCH_ARTICLES_PIM_FORM = 'products-fetch-articles-pim-form';
const ACTION_ID_PRODUCTS_FETCH_ARTICLES_PIM_FORM_SAVE = 'products-fetch-articles-pim-form-save';
const ACTION_ID_PRODUCTS_FETCH_ARTICLES_PIM_PRODUCTS = 'products-fetch-articles-pim-products';
const ACTION_ID_PRODUCTS_FETCH_PRODUCT_PRICE_AND_AMOUNT = 'products-fetch-product-price-and-amount';
const ACTION_ID_PRODUCTS_FETCH_PRODUCT_PRICE_AND_REPORT = 'products-fetch-product-price-and-report';
const ACTION_ID_PRODUCTS_FETCH_EXCEL = 'products-fetch-excel';
const ACTION_ID_PRODUCTS_EXCEL_UPLOAD = 'products-excel-upload';
const ACTION_ID_PRODUCTS_SAVE = 'products-save';
const ACTION_ID_PRODUCTS_DELETE = 'products-delete';
const ACTION_ID_PRODUCTS_FETCH_IMAGE = 'products-fetch-image';
const ACTION_ID_PRODUCTS_IMAGE_UPLOAD = 'products-image-upload';
const ACTION_ID_PRODUCTS_FETCH_ASSOCIATIONS = 'products-fetch-associations';
const ACTION_ID_PRODUCTS_SAVE_ASSOCIATIONS = 'products-save-associations';
const ACTION_ID_USERS_INVITE = 'users-invite';
const ACTION_ID_USERS_REINVITE = 'users-reinvite';
const ACTION_ID_USERS_REGISTER = 'users-register';
const ACTION_ID_USERS_CONFIRM_EMAIL = 'users-confirm-email';
const ACTION_ID_USERS_RESTORE_PASSWORD = 'users-restore-password';
const ACTION_ID_USERS_RESTORE_PASSWORD_LINK = 'users-restore-password-link';
const ACTION_ID_USERS_RESTORE_PASSWORD_CHECK = 'users-restore-password-check';
const ACTION_ID_USERS_LOGIN = 'users-login';
const ACTION_ID_DIFFERENT_USERS_LOGIN = 'different-users-login';
const ACTION_ID_USERS_CHECK_TOKEN = 'users-check-token';
const ACTION_ID_USERS_FETCH = 'users-fetch';
const ACTION_ID_USERS_FETCH_APPS = 'users-fetch-apps';
const ACTION_ID_USERS_FETCH_USER = 'users-fetch-user';
const ACTION_ID_USERS_FETCH_ROLES = 'users-fetch-roles';
const ACTION_ID_USERS_FETCH_RS = 'users-fetch-rs';
const ACTION_ID_USERS_FETCH_FLAGS = 'users-fetch-flags';
const ACTION_ID_USERS_SAVE = 'users-save';
const ACTION_ID_USERS_DEACTIVATE = 'users-deactivate';
const ACTION_ID_USERS_PROFILE_FETCH = 'users-profile-fetch';
const ACTION_ID_USERS_PROFILE_SAVE = 'users-profile-save';
const ACTION_ID_USERS_COMPANY_INN_FETCH = 'users-company-inn-fetch';
const ACTION_ID_PROJECTS_FETCH = 'projects-fetch';
const ACTION_ID_PROJECTS_SAVE = 'projects-save';
const ACTION_ID_PROJECTS_DELETE = 'projects-delete';
const ACTION_ID_NOTIFICATIONS_FETCH = 'notifications-fetch';
const ACTION_ID_NEWS_ADMIN_FETCH = 'news-admin-fetch';
const ACTION_ID_NEWS_ADMIN_ITEM_FETCH = 'news-admin-item-fetch';
const ACTION_ID_NEWS_ADMIN_ITEM_UPDATE = 'news-admin-item-update';
const ACTION_ID_NEWS_ADMIN_ITEM_DELETE = 'news-admin-item-delete';
const ACTION_ID_NEWS_FETCH = 'news-fetch';
const ACTION_ID_NEWS_FETCH_DETAIL = 'news-fetch-detail';
const ACTION_ID_FEEDBACK_ADMIN_FETCH = 'feedback-admin-fetch';
const ACTION_ID_FEEDBACK_ADMIN_ITEM_FETCH = 'feedback-admin-item-fetch';
const ACTION_ID_FEEDBACK_ITEM_CREATE = 'feedback-item-create';
const ACTION_ID_FEEDBACK_ITEM_UPDATE = 'feedback-item-update';

const ACTION_TITLE_DELETE = 'Удалить';
const ACTION_TITLE_EDIT = 'Редактировать';
const ACTION_TITLE_EDIT_BLANK = 'Редактировать в новой вкладке';
const ACTION_TITLE_COPY = 'Копировать';
const ACTION_TITLE_COPY_AND_OPEN = 'Копировать и открыть';
const ACTION_TITLE_XLSX = 'Скачать Excel';
const ACTION_TITLE_XLSX_PRESALE = 'Скачать Excel расширенный';
const ACTION_TITLE_XLSX_COMMERCIAL_OFFER = 'Скачать коммерческое предложение';
const ACTION_TITLE_XLSX_PIM = 'Скачать характеристики';
const ACTION_TITLE_SEND_TO_CRM = 'Отправить в CRM';
const ACTION_TITLE_HISTORY = 'История изменений';

const ERROR_NO_DATA = 'Не получены данные';
const ERROR_NO_DATA_FILE = `${ERROR_NO_DATA}: файл.`;
const ERROR_FILE_MODIFIED = 'Файл был изменен или удален. Выберите файл повторно или добавьте новый.';

const DISCLAIMER_LIST_EMPTY = 'Список пуст.';
const DISCLAIMER_LIST_FILTERED_EMPTY = 'По заданным параметрам элементов не найдено.';

const INPUT_NUMBER_RESTRICTED_CHARS = ['+', 'e', 'arrowdown', 'arrowup'];
const INPUT_TEXTAREA_ROWS_DEFAULT = 3;

const CURRENCY_DEFAULT = USD;
const CURRENCY_DEFAULT_BASE = 100;
const ROUND_DAY_MS = 86400000;
const CATEGORY_NAME_RANDOM_ARTICUL = 'random_article';
const CATEGORY_NAME_OPTIONS = 'options';
const PAGE_SIZE = 30;
const PAGE_CONFIGURATION = {
    pageCount: 1,
    pageRangeDisplayed: 6,
};
const ALLOWED_EXCEL_FILE_EXTENSIONS = ['xls', 'xlsx'];
const ALLOWED_IMG_FILE_EXTENSIONS = ['png', 'jpg', 'jpeg'];
const MAX_FILE_SIZE = 10485760;
const FAKE_SCROLLBAR_MAX_LIST_LENGTH = 5;

export {
    ACTION_ID_CATEGORIES_FETCH,
    ACTION_ID_CATEGORIES_FETCH_SETTINGS,
    ACTION_ID_CATEGORIES_SAVE,
    ACTION_ID_COEFFS_FETCH,
    ACTION_ID_COEFFS_SAVE,
    ACTION_ID_USER_SETTINGS_FETCH,
    ACTION_ID_ASSOCIATIONS_FETCH_EXCEL,
    ACTION_ID_ASSOCIATIONS_UPLOAD_EXCEL,
    ACTION_ID_STORAGES_FETCH,
    ACTION_ID_STORAGES_SAVE,
    ACTION_ID_PRICES_MONITORING_FETCH,
    ACTION_ID_PRICES_MONITORING_FETCH_EXCEL,
    ACTION_ID_PRICES_MONITORING_FETCH_PRICES,
    ACTION_ID_PAGES_FETCH,
    ACTION_ID_CONFIGURATIONS_FETCH,
    ACTION_ID_CONFIGURATIONS_FETCH_EXCEL,
    ACTION_ID_CONFIGURATIONS_FETCH_DETAIL,
    ACTION_ID_CONFIGURATIONS_FETCH_SUM,
    ACTION_ID_CONFIGURATIONS_SEND_TO_CRM,
    ACTION_ID_CONFIGURATIONS_SAVE,
    ACTION_ID_CONFIGURATIONS_DELETE,
    ACTION_ID_CONFIGURATIONS_COPY,
    ACTION_ID_CONFIGURATIONS_UPLOAD,
    ACTION_ID_CONFIGURATIONS_UPLOAD_CANCEL,
    ACTION_ID_CONFIGURATIONS_SPECIFY,
    ACTION_ID_CONFIGURATIONS_FETCH_HISTORY,
    ACTION_ID_CONFIGURATOR_INIT,
    ACTION_ID_CONFIGURATOR_VALIDATE,
    ACTION_ID_CONFIGURATOR_FETCH_FILTERS,
    ACTION_ID_CONFIGURATOR_FETCH_RANDOM_ARTICUL,
    ACTION_ID_CONFIGURATOR_FETCH_SLOT_SEARCH_PRODUCTS,
    ACTION_ID_CONFIGURATOR_FETCH_SLOT_SEARCH_PRODUCTS_ATTRIBUTES,
    ACTION_ID_CONFIGURATOR_SET_SLOT_SEARCH_PRODUCTS_RECOMMENDATION,
    ACTION_ID_CONFIGURATOR_FETCH_FIELDS,
    ACTION_ID_PRODUCTS_FETCH,
    ACTION_ID_PRODUCTS_FETCH_FORM,
    ACTION_ID_PRODUCTS_FETCH_ARTICLES_PIM_FORM,
    ACTION_ID_PRODUCTS_FETCH_ARTICLES_PIM_FORM_SAVE,
    ACTION_ID_PRODUCTS_FETCH_ARTICLES_PIM_PRODUCTS,
    ACTION_ID_PRODUCTS_FETCH_PRODUCT_PRICE_AND_AMOUNT,
    ACTION_ID_PRODUCTS_FETCH_PRODUCT_PRICE_AND_REPORT,
    ACTION_ID_PRODUCTS_FETCH_EXCEL,
    ACTION_ID_PRODUCTS_EXCEL_UPLOAD,
    ACTION_ID_PRODUCTS_SAVE,
    ACTION_ID_PRODUCTS_DELETE,
    ACTION_ID_PRODUCTS_FETCH_IMAGE,
    ACTION_ID_PRODUCTS_IMAGE_UPLOAD,
    ACTION_ID_PRODUCTS_FETCH_ASSOCIATIONS,
    ACTION_ID_PRODUCTS_SAVE_ASSOCIATIONS,
    ACTION_ID_USERS_INVITE,
    ACTION_ID_USERS_REINVITE,
    ACTION_ID_USERS_REGISTER,
    ACTION_ID_USERS_CONFIRM_EMAIL,
    ACTION_ID_USERS_RESTORE_PASSWORD,
    ACTION_ID_USERS_RESTORE_PASSWORD_LINK,
    ACTION_ID_USERS_RESTORE_PASSWORD_CHECK,
    ACTION_ID_USERS_LOGIN,
    ACTION_ID_DIFFERENT_USERS_LOGIN,
    ACTION_ID_USERS_CHECK_TOKEN,
    ACTION_ID_USERS_FETCH,
    ACTION_ID_USERS_FETCH_APPS,
    ACTION_ID_USERS_FETCH_USER,
    ACTION_ID_USERS_FETCH_ROLES,
    ACTION_ID_USERS_FETCH_RS,
    ACTION_ID_USERS_FETCH_FLAGS,
    ACTION_ID_USERS_SAVE,
    ACTION_ID_USERS_DEACTIVATE,
    ACTION_ID_USERS_PROFILE_FETCH,
    ACTION_ID_USERS_PROFILE_SAVE,
    ACTION_ID_USERS_COMPANY_INN_FETCH,
    ACTION_ID_PROJECTS_FETCH,
    ACTION_ID_PROJECTS_SAVE,
    ACTION_ID_PROJECTS_DELETE,
    ACTION_ID_NOTIFICATIONS_FETCH,
    ACTION_ID_NEWS_ADMIN_FETCH,
    ACTION_ID_NEWS_ADMIN_ITEM_FETCH,
    ACTION_ID_NEWS_ADMIN_ITEM_UPDATE,
    ACTION_ID_NEWS_ADMIN_ITEM_DELETE,
    ACTION_ID_NEWS_FETCH,
    ACTION_ID_NEWS_FETCH_DETAIL,
    ACTION_ID_FEEDBACK_ADMIN_FETCH,
    ACTION_ID_FEEDBACK_ADMIN_ITEM_FETCH,
    ACTION_ID_FEEDBACK_ITEM_CREATE,
    ACTION_ID_FEEDBACK_ITEM_UPDATE,
    ACTION_TITLE_EDIT,
    ACTION_TITLE_EDIT_BLANK,
    ACTION_TITLE_COPY,
    ACTION_TITLE_COPY_AND_OPEN,
    ACTION_TITLE_XLSX,
    ACTION_TITLE_XLSX_PRESALE,
    ACTION_TITLE_XLSX_COMMERCIAL_OFFER,
    ACTION_TITLE_XLSX_PIM,
    ACTION_TITLE_SEND_TO_CRM,
    ACTION_TITLE_HISTORY,
    ACTION_TITLE_DELETE,
    ERROR_NO_DATA,
    ERROR_NO_DATA_FILE,
    ERROR_FILE_MODIFIED,
    DISCLAIMER_LIST_EMPTY,
    DISCLAIMER_LIST_FILTERED_EMPTY,
    INPUT_NUMBER_RESTRICTED_CHARS,
    INPUT_TEXTAREA_ROWS_DEFAULT,
    CURRENCY_DEFAULT,
    CURRENCY_DEFAULT_BASE,
    ROUND_DAY_MS,
    CATEGORY_NAME_RANDOM_ARTICUL,
    CATEGORY_NAME_OPTIONS,
    PAGE_SIZE,
    PAGE_CONFIGURATION,
    ALLOWED_EXCEL_FILE_EXTENSIONS,
    ALLOWED_IMG_FILE_EXTENSIONS,
    MAX_FILE_SIZE,
    FAKE_SCROLLBAR_MAX_LIST_LENGTH,
};
